// JsFromRoutes CacheKey 79cd56103fc34b50e71f6deebd50231c
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/util/requests'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/admin/experiments'),
  create: /* #__PURE__ */ definePathHelper('post', '/admin/experiments'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/experiments/:id'),
}
